import { ScreenRegistry } from 'client/app/registry';
import { route } from 'common/ui/navigation';

export const simulationRoutes = {
  simulationDetailsSubscreen: route<{
    simulationId: string;
    subscreenId?: string;
  }>(`/${ScreenRegistry.SIMULATION_DETAILS}/:simulationId/:subscreenId?`),
  openInSimulationDetails: route<{ simulationId: string }>(
    `/${ScreenRegistry.SIMULATION_DETAILS}/:simulationId`,
  ),
};

export const executionRoutes = {
  openInExecutionDetails: route<{ executionId: string }>(
    `/${ScreenRegistry.EXECUTION_DETAILS}/:executionId`,
  ),
  executionDetailsSubscreen: route<{
    executionId: string;
    subscreenId?: string;
  }>(`/${ScreenRegistry.EXECUTION_DETAILS}/:executionId/:subscreenId?`),
};

export const workflowRoutes = {
  base: route<void>('/workflow'),
  openInWorkflowBuilder: route<{ workflowId: string }>(
    `/${ScreenRegistry.WORKFLOW}/:workflowId`,
  ),
  openInCherryPicker: route<{ workflowId: string }>(
    `/${ScreenRegistry.CHERRY_PICKER}/:workflowId`,
  ),
  editForm: route<{ workflowId: string }>(`/${ScreenRegistry.FORM}/:workflowId`),
};

export const graceRoutes = {
  openSimulationInGrace: route<{ simulationId: string }>(
    `/#/${ScreenRegistry.VIS_VIEW}/view/chat?simulation_id=:simulationId`,
  ),
};

export const doeTemplateRoutes = {
  edit: route<{ workflowId: string }>(`/${ScreenRegistry.DOE_TEMPLATE}/:workflowId`),
  design: route<{ workflowId?: string }>(`/${ScreenRegistry.DOE_DESIGN}/:workflowId?`),
};

export const responseAnalysisRoutes = {
  base: route<void>(`/${ScreenRegistry.RESPONSE_ANALYSIS}`),
};

export const templateWorkflowRoutes = {
  base: route<void>(`/${ScreenRegistry.TEMPLATE_WORKFLOWS}`),
  createFromSimulation: route<{ simulationId: string }>(
    `/${ScreenRegistry.TEMPLATE_WORKFLOWS}/create/:simulationId`,
  ),
  editTemplate: route<{ id: string }>(`/${ScreenRegistry.TEMPLATE_WORKFLOWS}/:id/edit`),

  createForm: route<{ id: string }>(`/${ScreenRegistry.FORM}/create/:id`),
};

export const protocolsRoutes = {
  createFromWorkflow: route<{ id: string; name: string }>(
    `/${ScreenRegistry.PROTOCOL}/create/:id/:name`,
  ),
  editProtocol: route<{ id: string; version: ProtocolVersion | string }>(
    `/${ScreenRegistry.PROTOCOL}/:id/version/:version/edit`,
  ),
  copyProtocol: route<{ id: string; version: ProtocolVersion | string }>(
    `/${ScreenRegistry.PROTOCOL}/:id/version/:version/copy`,
  ),
  createProtocolInstance: route<{ id: string }>(
    `/${ScreenRegistry.PROTOCOL}/instance/create/:id`,
  ),
  editProtocolInstance: route<{ id: string }>(
    `/${ScreenRegistry.PROTOCOL}/instance/:id/edit`,
  ),
};

export const experimentsRoutes = {
  base: route<void>(`/${ScreenRegistry.EXPERIMENTS}`),
  workflows: route<void>(`/${ScreenRegistry.EXPERIMENTS}/workflows`),
  incoming: route<void>(`/${ScreenRegistry.EXPERIMENTS}/incoming`),
  drafts: route<void>(`/${ScreenRegistry.EXPERIMENTS}/drafts`),
  doeTemplates: route<void>(`/${ScreenRegistry.EXPERIMENTS}/doe-templates`),
  executions: route<void>(`/${ScreenRegistry.EXPERIMENTS}/executions`),
  detail: route<{ id: string }>(`/${ScreenRegistry.EXPERIMENT_DETAIL}/:id`),
  bioprocess: route<{ experimentId: string; derivationId: string }>(
    `/${ScreenRegistry.EXPERIMENT_DETAIL}/:experimentId/dataset-derivation/:derivationId`,
  ),
  exampleGallery: route<void>(`/${ScreenRegistry.EXPERIMENTS}/example-gallery`),
  tutorials: route<void>(`/${ScreenRegistry.EXPERIMENTS}/tutorials`),
  examples: route<void>(`/${ScreenRegistry.EXPERIMENTS}/examples`),
  protocols: route<void>(`/${ScreenRegistry.EXPERIMENTS}/protocols`),
};

export const workTreeRoutes = {
  workTreeExperiment: route<{ experimentId: string }>(
    `/${ScreenRegistry.WORK_TREE}/experiment/:experimentId`,
  ),
};

export type VisualizationRouteProps = {
  /**
   * Visualization ID within the simulation
   */
  id: string;
  /**
   * Specific path within visserver to use
   */
  visserverApp: string;
  simulationID: string;
};

export type VisViewRouteProps = {
  visserverApp: string;
};

export const visualizationRoutes = {
  base: route<VisualizationRouteProps>(
    `/${ScreenRegistry.VISUALIZATION}/simulation/:simulationID/:visserverApp/:id`,
  ),
  visView: route<VisViewRouteProps>(`/${ScreenRegistry.VIS_VIEW}/:visserverApp`),
};
